import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name candy-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOC41IDguNS0xIDFhNC45NSA0Ljk1IDAgMCAwIDcgN2wxLTEiIC8+CiAgPHBhdGggZD0iTTExLjg0MyA2LjE4N0E0Ljk0NyA0Ljk0NyAwIDAgMSAxNi41IDcuNWE0Ljk0NyA0Ljk0NyAwIDAgMSAxLjMxMyA0LjY1NyIgLz4KICA8cGF0aCBkPSJNMTQgMTYuNVYxNCIgLz4KICA8cGF0aCBkPSJNMTQgNi41djEuODQzIiAvPgogIDxwYXRoIGQ9Ik0xMCAxMHY3LjUiIC8+CiAgPHBhdGggZD0ibTE2IDcgMS01IDEuMzY3LjY4M0EzIDMgMCAwIDAgMTkuNzA4IDNIMjF2MS4yOTJhMyAzIDAgMCAwIC4zMTcgMS4zNDFMMjIgN2wtNSAxIiAvPgogIDxwYXRoIGQ9Im04IDE3LTEgNS0xLjM2Ny0uNjgzQTMgMyAwIDAgMCA0LjI5MiAyMUgzdi0xLjI5MmEzIDMgMCAwIDAtLjMxNy0xLjM0MUwyIDE3bDUtMSIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/candy-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CandyOff: IconNode = [
  ['path', { d: 'm8.5 8.5-1 1a4.95 4.95 0 0 0 7 7l1-1' }],
  ['path', { d: 'M11.843 6.187A4.947 4.947 0 0 1 16.5 7.5a4.947 4.947 0 0 1 1.313 4.657' }],
  ['path', { d: 'M14 16.5V14' }],
  ['path', { d: 'M14 6.5v1.843' }],
  ['path', { d: 'M10 10v7.5' }],
  ['path', { d: 'm16 7 1-5 1.367.683A3 3 0 0 0 19.708 3H21v1.292a3 3 0 0 0 .317 1.341L22 7l-5 1' }],
  ['path', { d: 'm8 17-1 5-1.367-.683A3 3 0 0 0 4.292 21H3v-1.292a3 3 0 0 0-.317-1.341L2 17l5-1' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
];

export default CandyOff;
