import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name calendar-sync
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTB2NGg0IiAvPgogIDxwYXRoIGQ9Im0xMSAxNCAxLjUzNS0xLjYwNWE1IDUgMCAwIDEgOCAxLjUiIC8+CiAgPHBhdGggZD0iTTE2IDJ2NCIgLz4KICA8cGF0aCBkPSJtMjEgMTgtMS41MzUgMS42MDVhNSA1IDAgMCAxLTgtMS41IiAvPgogIDxwYXRoIGQ9Ik0yMSAyMnYtNGgtNCIgLz4KICA8cGF0aCBkPSJNMjEgOC41VjZhMiAyIDAgMCAwLTItMkg1YTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmg0LjMiIC8+CiAgPHBhdGggZD0iTTMgMTBoNCIgLz4KICA8cGF0aCBkPSJNOCAydjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/calendar-sync
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CalendarSync: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 10v4h4' }],
    ['path', { d: 'm11 14 1.535-1.605a5 5 0 0 1 8 1.5' }],
    ['path', { d: 'M16 2v4' }],
    ['path', { d: 'm21 18-1.535 1.605a5 5 0 0 1-8-1.5' }],
    ['path', { d: 'M21 22v-4h-4' }],
    ['path', { d: 'M21 8.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h4.3' }],
    ['path', { d: 'M3 10h4' }],
    ['path', { d: 'M8 2v4' }],
  ],
];

export default CalendarSync;
